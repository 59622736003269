// Funzione per rilevare il tipo di dispositivo
const androidIcon =  document.querySelector('.google-play');
const iosIcon = document.querySelector('.app-store')

function detectDevice() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes("android")) {
        iosIcon.classList.add("hidden");
    } else if (userAgent.includes("iphone") || userAgent.includes("ipad") || userAgent.includes("ipod")) {
        androidIcon.classList.add("hidden");
    }
}

// Rileva il dispositivo al caricamento della pagina
if (androidIcon && iosIcon) {
    detectDevice();
}