import SwiperSlider from "./abstract/SwiperSlider";
import $ from 'jquery'

export default class SliderFooter extends SwiperSlider {
  constructor(element, context) {
    super(element, context);
    $(() => {
      
      this.options = {
        spaceBetween: 40,
        slidesPerView: 'auto',
        breakpoints: {
          1400: {
              slidesPerView: 19,
          }
        },
        centeredSlides: false,
        loop: true,
        speed:  1000,
        allowTouchMove: false,
        autoplay: {
          delay: 1,
          disableOnInteraction: false,
        },
        on: {
          init: () => {
            setTimeout(() => {
              this.getSwiper().autoplay.start();
            }, 50)
          }
        },
      };

      $(() => {
        this.init()
      })
      
    });
  }
}