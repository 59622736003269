import SwiperSlider from "./abstract/SwiperSlider";
import $ from 'jquery'

export default class SliderSfondi extends SwiperSlider {
  constructor(element, context) {
    super(element, context);
    $(() => {
      
      this.options = {
        spaceBetween: 0,
        allowTouchMove: false,
        effect: "fade",
        autoplay: true,
        loop: true,

        on: {
          init: () => {
            setTimeout(() => {
              this.getSwiper().autoplay.start();
            }, 50)
          }
        },
      };

      $(() => {
        this.init()
      })
      
    });
  }
}