import Registry from "./models/Registry";
import SliderFooter from "./components/SliderFooter";
import SliderSfondi from "./components/SliderSfondi";
import './components/Fadein';
import './components/Faderight'
import './components/MainMenu';
import './components/Device';

class App {
  constructor(context) {
    this.registry = new Registry();
    this.setupUnloadScroll();
    this.registerComponents();
    this.initController(context);
    this.patchIos();
    this.setvh();
  }

  initController(contextData) {
    const Context = this.registry.getContext(contextData);
    new Context(contextData);
  }

  registerComponents() {
    this.registry
    .addComponent('SliderFooter', SliderFooter)
    .addComponent('SliderSfondi', SliderSfondi);

  }

  patchIos() {
    window.addEventListener('resize', () => this.appHeight())
    this.appHeight()
  }

  appHeight() {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  setvh() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  
  setupUnloadScroll() {
    setTimeout(function() {

      if (window.location.hash) {
        const targetElement = document.querySelector(window.location.hash);
        if (targetElement) {
          targetElement.scrollIntoView();
        }
      } else {
        window.scrollTo(0, 0);
      }
    }, 400);
  }
  
}

window.AppInit = function(context) {
    new App(context);
};
