import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();
import $ from 'jquery';


$( document ).ready(function() {
  
  const boxes = gsap.utils.toArray('.faderight');
  
  var tl = gsap.timeline();
  boxes.forEach((box, i) => {
    let delayBase = 0
    let calcDelay = parseFloat(`0.${i*3}`);
    let delay = delayBase + calcDelay

    let animation = gsap.fromTo(box, {
      opacity: 0,
      x: 50,
    }, 
    {
      delay: 1 + delay,
      ease: 'ease',
      opacity: 1,
      x: 0,
      duration: 2,
    });

    

  }); 
});