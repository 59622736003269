import $ from 'jquery';
import {
  $Doc,
  $Win
} from "../utils/jQueryGlobals";
import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();

gsap.registerPlugin(ScrollTrigger);

$Win.on('scroll', () => {
  if ($Doc.scrollTop() > 100) {
    $('header').addClass('no-top');
  } else {
    $('header').removeClass('no-top');
  }
});

let hamburgher = $('.hamburger');

$(hamburgher).on('click', function () {
  if ($(this).hasClass('is-active')) {
    $(this).removeClass('is-active');
    $('#menu-items').hide();
    $('html, body').removeClass('open-menu no-scroll');
  } else {
    $(this).addClass('is-active');
    $('#menu-items').show();
    $('html, body').addClass('open-menu no-scroll');

    let titoliHeader = document.querySelectorAll('.menu-item > a');


    titoliHeader.forEach(element => {
      gsap.from(element, {
        y: '110%',
        delay: 0.3,
        opacity: 0,
        stagger: { each: 0.1 },
      });
    });

  }
})